<template>
  <div>
    <!-- list filters -->
    <v-card>
      <v-card-title>
        Utilisateurs
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
<!--        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="$store.state.user.field.name"
            placeholder="Rechercher par le nom"
            label="Rechercher par le nom"
            outlined
            dense
            hide-details
            clearable
            @click:clear="refreshUsers"
            @keyup="filterUsers"
          ></v-text-field>
        </v-col>-->

        <!-- plan filter -->
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="$store.state.user.field.role_id"
            :items="$store.getters['role/roles']"
            item-value="id"
            item-text="label"
            label="Filtrer par rôle"
            placeholder="Filtrer par rôle"
            outlined
            dense
            clearable
            persistent-hint
            single-line
            @input="getUsersPerRole"
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-1"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="$store.state.user.field.keyword"
          placeholder="Rechercher par nom ou email"
          label="Rechercher  par nom ou email"
          outlined
          hide-details
          dense
          clearable
          class="user-search me-3 mb-4"
          @click:clear="refreshUsers"
          @keyup="filterUsers"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-tooltip
            left
            color="primary"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mb-4 me-3"
                small
                v-bind="attrs"
                @click.stop="openForm()"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <span>Nouvel utilisateur</span>
          </v-tooltip>

          <v-tooltip
            top
            color="secondary"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="secondary"
                class="mb-4 me-3"
                v-bind="attrs"
                small
                v-on="on"
                @click.prevent="refreshUsers"
              >
                <v-icon>{{ icons.mdiRefresh }}</v-icon>
              </v-btn>
            </template>
            <span>Raffraichir</span>
          </v-tooltip>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        loading-text="Chargement..."
        no-data-text="Aucun résultat"
        no-results-text="Aucun résultat"
        :headers="headers"
        :items="$store.getters['user/users']"
        :items-per-page="$store.getters['user/per_page']"
        :page.sync="$store.getters['user/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        :loading="loading"
        @page-count="currentPage = $event"
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.profile_picture_path ? '' : 'primary'"
              :class="item.profile_picture_path ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.profile_picture_path"
                :src="`${item.profile_picture_path}`"
              ></v-img>
              <span v-else-if="item.name">{{ item.name !== null && item.name !== undefined ? item.name.slice(0,2).toUpperCase() : null }}</span>
              <v-img
                v-else
                :src="require('@/assets/images/avatars/1.png')"
              />
            </v-avatar>
            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }} {{ item.last_name }}</span>
              <small>{{ item.role.label }}</small>
            </div>
          </div>
        </template>
        <template #[`item.created_at`]="{item}">
          {{ formatingDate(`${item.created_at}`) }}
        </template>
        <template #[`item.updated_at`]="{item}">
          {{ formatingDate(`${item.updated_at}`) }}
        </template>
        <template #[`item.deleted_at`]="{item}">
          <v-chip
            v-show="item.deleted_at !== null && item.deleted_at !== undefined"
            small
            color="error"
          >
            {{ item.deleted_at !== null ? formatingDate(`${item.deleted_at}`) : null }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-list-item-title
                  @click.stop="openForm(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Modifier</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title
                  @click.stop="getUserDetails(item.id)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Détails</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title
                  @click.stop="confirmItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ item.deleted_at !== null && item.deleted_at !== undefined? icons.mdiRestore : icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ item.deleted_at !== null && item.deleted_at !== undefined? "Restaurer" : "Supprimer" }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title
                  @click.stop="confirmDeleteItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteForever }}
                  </v-icon>
                  <span>Supprimer définitivement</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-select
              v-model="$store.state.user.field.paginate"
              :items="itemsPerPage"
              item-text="text"
              item-value="value"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getUsersPerPage"
            ></v-select>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getUsers"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <user-form
      :dialog="dialog"
      :object="item"
      @clickout="dialog = false"
      @submitted="loading"
    />
    <confirm-dialog
      :message="messageDialog"
      :dialog-delete="dialogDelete"
      @clickout="dialogDelete = false"
      @submitted="deleteItem"
    />
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiRefresh,
  mdiPencilOutline,
  mdiRestore,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { customFormatDate } from '@core/utils/filter'
import UserForm from '@core/components/user/UserForm'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { notify } from '@core/utils/notification'
import store from '@/store'
import router from '@/router'

export default {
  name: 'Users',
  components: { ConfirmDialog, UserForm },
  setup() {
    const loading = ref(false)
    const dialogDelete = ref(false)
    const message = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const error = ref(null)
    const isAddNewUserSidebarActive = ref(false)
    const forcedelete = ref(false)
    const rolesItems = ref([])
    const messageDialog = ref('')
    const itemsPerPage = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
    ]
    const dialog = ref(false)
    const item = ref({})

    const openForm = val => {
      if (val) item.value = val
      else {
        item.value = {
          id: null,
          name: null,
          last_name: null,
          email: null,
          phone_number: null,
          civility: null,
          password: null,
          password_confirmation: null,
          bio: null,
          role_id: null,
        }
      }
      dialog.value = true
    }

    const getUsers = (page = 1) => {
      loading.value = true
      store.dispatch('user/getUsers', { page, field: store.state.user.field }).then(() => {
        currentPage.value = store.getters['user/current_page']
        lastPage.value = store.getters['user/last_page']
        loading.value = false
        // eslint-disable-next-line no-shadow
      }).catch(errors => {
        message.value = errors.response.data.message
        error.value = errors.response.data.message
        loading.value = false
      })
    }

    const refreshUsers = (page = 1) => {
      loading.value = true
      store.state.user.field.keyword = null
      store.state.user.field.name = null
      store.dispatch('user/refreshUsers', { page, field: store.state.user.field }).then(() => {
        currentPage.value = store.getters['user/current_page']
        lastPage.value = store.getters['user/last_page']
        loading.value = false
        // eslint-disable-next-line no-shadow
      }).catch(errors => {
        message.value = errors.response.data.message
        error.value = errors.response.data.message
        loading.value = false
      })
    }

    const getUsersPerPage = per_page => {
      loading.value = true
      store.state.user.field.paginate = per_page
      store.dispatch('user/getUsers', { page: store.getters['user/current_page'], field: store.state.user.field }).then(() => {
        currentPage.value = store.getters['user/current_page']
        lastPage.value = store.getters['user/last_page']
        loading.value = false
        // eslint-disable-next-line no-shadow
      }).catch(errors => {
        message.value = errors.response.data.message
        error.value = errors.response.data.message
        loading.value = false
      })
    }

    const getUsersPerRole = role_id => {
      loading.value = true
      store.dispatch('user/getUsers', { page: store.getters['user/current_page'], field: store.state.user.field }).then(() => {
        currentPage.value = store.getters['user/current_page']
        lastPage.value = store.getters['user/last_page']
        loading.value = false
        // eslint-disable-next-line no-shadow
      }).catch(errors => {
        message.value = errors.response.data.message
        error.value = errors.response.data.message
        loading.value = false
      })
    }

    const getRoles = (page = 1) => {
      store.dispatch('role/getRoles', { page, field: store.state.role.field }).then(() => {
        store.getters['role/roles'].forEach(elt => {
          rolesItems.value.push({ text: elt.label, value: elt.id })
        })
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        console.log(error)
      })
    }

    const filterUsers = () => {
      setTimeout(() => {
        getUsers(store.getters['user/current_page'])
      }, 1000)
    }

    const formatingDate = date => customFormatDate(date, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })

    const confirmItem = object => {
      messageDialog.value = object.deleted_at === null || item.value.deleted_at === undefined? `Voulez-vous supprimer l'utilisateur ${object.name}?` : `Voulez-vous restaurer l'utilisateur ${object.name}?`
      item.value = object
      dialogDelete.value = true
      forcedelete.value = false
    }

    const confirmDeleteItem = object => {
      messageDialog.value = `Voulez-vous supprimer définitivement l'utilisateur ${object.name}?`
      item.value = object
      dialogDelete.value = true
      forcedelete.value = true
    }

    const deleteItem = () => {
      if (forcedelete.value === false) {
        if (item.value.deleted_at === null || item.value.deleted_at === undefined) {
          store.dispatch('user/deleteUser', item.value).then(response => {
            dialogDelete.value = false
            notify(true, true, response.message)
          }).catch(err => {
            message.value = err.response.data.message
            error.value = err.response.data.message
            dialogDelete.value = false
            notify(true, true, err.response.data.message)
          })
        } else {
          store.dispatch('user/restoreUser', item.value).then(response => {
            dialogDelete.value = false
            notify(true, true, response.message)
          }).catch(err => {
            message.value = err.response.data.message
            error.value = err.response.data.message
            dialogDelete.value = false
            notify(true, true, err.response.data.message)
          })
        }
      } else {
        store.dispatch('user/forceDeleteUser', item.value).then(response => {
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          dialogDelete.value = false
          notify(true, true, err.response.data.message)
        })
      }
    }

    onMounted(() => {
      getUsers(store.getters['user/current_page'])
      getRoles()
    })

    const getUserDetails = user_id => {
      router.push({ name: 'userdetails', params: { id: user_id } })
    }

    return {
      getUserDetails,
      deleteItem,
      confirmItem,
      messageDialog,
      dialogDelete,
      dialog,
      openForm,
      forcedelete,
      confirmDeleteItem,
      item,
      refreshUsers,
      rolesItems,
      getUsersPerRole,
      getUsers,
      formatingDate,
      getUsersPerPage,
      filterUsers,
      isAddNewUserSidebarActive,
      loading,
      error,
      currentPage,
      lastPage,
      message,
      itemsPerPage,
      headers: [
        { text: 'NOM', value: 'name', sortable: false },
        { text: 'EMAIL', value: 'email', sortable: false },
        { text: 'DATE DE CREATION', value: 'created_at', sortable: false },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at', sortable: false },
        { text: 'DATE DE SUPPRESSION', value: 'deleted_at', sortable: false },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ],
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiRefresh,
        mdiPencilOutline,
        mdiRestore,
      },
    }
  },
}
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
